import {sidebarCollapsedAtom} from 'atoms/ui'
import clsx from 'clsx'
import {useAuth} from 'data/auth'
import {useAtom} from 'jotai'
import {LoginButton} from './button'
import {
  CSGOIcon,
  DotaIcon,
  FacebookIcon,
  GiftIcon,
  HomeIcon,
  DiscordIcon,
  TelegramIcon,
  XMarkIcon,
  DiceIcon,
  CoinsIcon,
  CashOutIcon,
} from './icons'
import Logo from './logo'
import type {NavItemProps} from './nav-item'
import NavItem from './nav-item'

const items: NavItemProps[] = [
  {name: 'TRANG CHỦ', icon: <HomeIcon width={23} height={23} />, href: '/'},
  {name: 'DOTA', icon: <DotaIcon width={23} height={23} />, href: '/dota'},
  {name: 'CSGO', icon: <CoinsIcon width={25} height={25} />, href: '/csgo'},
  {
    name: 'ĐẾ CHẾ',
    icon: <CoinsIcon width={23} height={23} />,
    href: '/deche',
  },
  {
    name: '500',
    icon: <CoinsIcon width={23} height={23} />,
    href: '/500',
  },
  {
    name: 'CashOut',
    icon: <CashOutIcon width={23} height={23} />,
    href: '/cash-out',
  },
  {
    name: 'GIFT CODE',
    icon: <GiftIcon width={23} height={23} />,
    href: '/gift-code',
  },
]

function SidebarContent({
  className,
  ...props
}: JSX.IntrinsicElements['div']): JSX.Element {
  const [, setSidebarCollapsed] = useAtom(sidebarCollapsedAtom)
  const {data} = useAuth()

  function handleCloseSidebar() {
    setSidebarCollapsed(true)
  }

  return (
    <div
      {...props}
      className={clsx(
        'flex h-full flex-col border-secondary bg-primary py-3 md:border-r ',
        className
      )}
    >
      <div className="flex justify-end py-4 px-6 md:hidden">
        <XMarkIcon
          className="fill-secondary hover:cursor-pointer"
          onClick={handleCloseSidebar}
        />
      </div>
      <div className="ml-7 mt-2 hidden md:block">
        <Logo href="/" />
      </div>
      <div className="mt-5 flex-1 px-1">
        <ul>
          {items.map((item) => (
            <NavItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              href={item.href}
            />
          ))}
          {/* <NavItem
            textClass="-ml-4"
            name="SKIN CSGO/TF2"
            icon={<Logo className="-ml-2.5 -mr-2" />}
            href="https://520csgo.com/"
            target="_blank"
            rel="noreferrer"
          /> */}
        </ul>
        {data && !data.result?.authenticated ? (
          <div className="mt-12 text-center">
            <LoginButton className="px-5 text-center md:hidden" />
          </div>
        ) : null}
      </div>
      <div className="flex justify-center">
        <a
          className="mx-2"
          href="https://www.facebook.com/520csgo/"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon className="fill-secondary" width={22} height={22} />
        </a>
        <a
          className="mx-2"
          href="https://discord.com/invite/MBUFsYzwBA"
          target="_blank"
          rel="noreferrer"
        >
          <DiscordIcon className="fill-secondary" width={22} height={22} />
        </a>
        <a className="mx-2" href="#" target="_blank" rel="noreferrer">
          <TelegramIcon className="fill-secondary" width={22} height={22} />
        </a>
      </div>
    </div>
  )
}

export default SidebarContent
