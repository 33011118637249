import client from 'lib/api-client'
import type {GameItem, GameType} from './types'
import {BaseResponse} from 'data/orders'

const endpoint = '/api/items'

export function getGameItems(game: GameType) {
  return client.get<BaseResponse<GameItem[]>>(endpoint, {
    query: {game},
  })
}
